import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { makeRequest } from 'core/utils/request';
import parse from 'html-react-parser';
import './styles.css';
import Header from 'core/components/Header';
import NavBar from 'core/components/NavBar';
import Footer from 'core/components/Footer';
import { Product } from 'core/types/Product';
import ProductInfoLoader from '../components/Loaders/ContentLoader';
import ProductDescriptionLoader from '../components/Loaders/ProductDescriptionLoader';
import NoImage from 'core/assets/images/no-image.svg';

type ParamsType = {
  productId: string;
}

const ProductDetails = () => {
  const { productId } = useParams<ParamsType>();
  const [product, setProduct] = useState<Product>();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState('');

  useEffect(() => {
    setIsLoading(true);
    makeRequest({ url: `/products/${productId}` })
      .then(response => setProduct(response.data))
      .finally(() => setIsLoading(false));    
  }, [productId]);

  useEffect(() => {
    setImage(product?.productImages[0]?.imgUrl || NoImage);
  }, [product]);

  function handleImgChange(e: any) {
    setImage(e.target.src);
  }

  return (
    <>
      <Header />
      <NavBar />
      <div className="container my-4 main-content">                                
        <div className="row">
            <div className="col-sm-5">
              {isLoading ? <ProductInfoLoader /> : (
              <div className="product-images">
                  <div className="product-main-img">
                    <img 
                    src={image} 
                    alt={product?.name}
                    className="product-details-image" />
                  </div>
                  
                  <div className="product-gallery" id="thumbs-product">
                  {product?.productImages.map((item, index) => (              
                    <img width={80}
                      src={item.imgUrl}
                      className="img-thumb"
                      key={index}
                      alt={item.id + product?.name}
                      onClick={handleImgChange}
                    />
                  ))}
                  </div>
              </div>
              )}
            </div>
            
            <div className="col-sm-7">              
              <h2 className="section-title">{product?.name}</h2>              
            </div>
          </div> 
          <div className="row">
          {isLoading ? <ProductDescriptionLoader /> : (
            <div className="description-content">                    
              <h3>Descrição</h3>  
              {parse(product?.description + "")}

              <h3>Especificação</h3>  
              {parse(product?.specification + "")}
            </div>
          )}
          </div>          
      </div> 
      <Footer />
    </>
  );
}

export default ProductDetails;