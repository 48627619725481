import './styles.css'
import BannerHome from 'core/assets/images/banner_01.jpeg';

const Banner = () => {
  return (
    <div className="slider-area">
      <img src={BannerHome} alt="Banner" />
    </div>
  );
};

export default Banner;