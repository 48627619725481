import Footer from "core/components/Footer";
import Header from "core/components/Header";
import NavBar from "core/components/NavBar";
import './styles.css';

const Empresa = () => {
  return (
    <>
      <Header />
      <NavBar />
      <div className="container my-4 main-content">        
        <div className="row">
          <h2 className="section-title">A empresa</h2>   
          <h3>Missão</h3>
          <p>Nossa <strong>missão</strong> é oferecer produtos e serviços diferenciados para aplicações em água, e ainda proporcionar conforto em climatização, assim como, soluções técnicas específicas, através de processos ágeis, seguros e eficazes, com o objetivo de superar as expectativas dos nossos clientes.</p>

          <h3>Visão</h3>
          <p>Nossa <strong>visão</strong> é ser tradição e referência no estado de Goiás em qualidade e bem estar, entregando o melhor purificador de água, e o melhor no segmento de ar condicionado, ventilação / exaustão, sendo reconhecida como a melhor opção por clientes, colaboradores e fornecedores, sempre garantindo tranquilidade através da qualidade e segurança dos nossos produtos, serviços e relacionamento.</p>
          
          <h3>Valores</h3>
          <p>Nossos <strong>valores</strong> são pautados em:</p>
          <ul className="list-valores list-marked">
            <li>Integridade</li>
            <li>Conduta ética</li>
            <li>Transparência</li>
            <li>Responsabilidade</li>
            <li>Comprometimento</li>
            <li>Qualidade e excelência nos serviços prestados buscando melhoria contínua</li>
            <li>Valorização e respeito as pessoas</li>
            <li>Prevenção e cuidados com saúde e segurança de nossos funcionários e clientes</li>
            <li>Responsabilidade social e ambiental</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Empresa;