import './styles.css';

import Brand2 from 'core/assets/images/brands/brand2.png';
import Brand1 from 'core/assets/images/brands/brand1.png';
import Brand3 from 'core/assets/images/brands/brand3.png';
import Brand4 from 'core/assets/images/brands/brand4.png';
import Brand5 from 'core/assets/images/brands/brand5.png';
import Brand6 from 'core/assets/images/brands/brand6.png';
import Brand7 from 'core/assets/images/brands/brand7.png';
import Brand8 from 'core/assets/images/brands/brand8.png';

const Brands = () => {
  return (
    <div className="brands-area">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="brand-wrapper">
              <div className="brand-list">
                <img src={Brand1} alt="Green" />
                <img src={Brand2} alt="ibbl" />
                <img src={Brand3} alt="Philco" />
                <img src={Brand4} alt="Latina" />
                <img src={Brand5} alt="LG" />
                <img src={Brand6} alt="Agratto" />
                <img src={Brand7} alt="Samsung" />
                <img src={Brand8} alt="Elgin" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brands;