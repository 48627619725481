import { Product } from 'core/types/Product';
import { Link } from 'react-router-dom';
import './styles.scss';
import NoImage from 'core/assets/images/no-image.svg';

type Props ={
  product: Product;
}

function urlThumb(url: string) {
  return url.replace("https://etcetal.s3.us-east-2.amazonaws.com/", "https://etcetal.s3.us-east-2.amazonaws.com/thumbs/");
}

const ProductCard = ({ product }: Props) =>(
  <div className="col-sm-6 col-lg-4 col-xl-3">    
    <Link to={`/produto/${product.id}/${product.slug}`} key={product.id}>
      <div className="base-card product-card">
          <div className="card-top-container">
            <img src={urlThumb(product.productImages[0]?.imgUrl) || NoImage} alt={product.name} />
          </div>
          <div className="card-bottom-container">
            <h6>{product.name}</h6>
          </div>
        </div>
      </Link>   
  </div>
);

export default ProductCard;