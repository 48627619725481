import { Router, Switch, Route, Redirect } from 'react-router-dom';
import Home from './pages/Site/Home';
import Empresa from './pages/Site/Empresa';
import Servicos from './pages/Site/Servicos';
import Contato from './pages/Site/Contato';
import ProductDetails from './pages/Site/ProductDetails';
import Admin from 'pages/Admin';
import history from './core/utils/history';
import Auth from 'pages/Auth';
import Catalog from 'pages/Site/Catalog';
import ScrollToTop from 'pages/Site/components/ScrollToTop';

const Routes = () =>  (
    <Router history={history}>  
        <ScrollToTop>
            <Switch>
                <Route path="/" exact>
                    <Home />
                </Route>
                <Route path="/empresa" exact>
                    <Empresa />
                </Route>
                <Route path="/servicos" exact>
                    <Servicos />
                </Route>
                <Route path="/produtos" exact>
                    <Catalog idCategory={0} />
                </Route>
                <Route path="/produto/:productId">
                    <ProductDetails/>
                </Route>
                <Route path="/ar-condicionado" exact>
                    <Catalog idCategory={1} />
                </Route>           
                <Route path="/purificador" exact>
                    <Catalog idCategory={2} />
                </Route>
                <Route path="/climatizador" exact>
                    <Catalog idCategory={8} />
                </Route>
                <Route path="/bebedouro" exact>
                    <Catalog idCategory={6} />
                </Route>
    
                <Route path="/acessorio" exact>
                    <Catalog idCategory={3} />
                </Route>
                <Route path="/contato" exact>
                    <Contato />
                </Route>
                <Redirect from="/auth" to="/auth/login" exact/>
                <Route path="/auth">
                    <Auth />
                </Route>
                <Redirect from="/admin" to="/admin/products" exact />     
                <Route path="/admin"> 
                    <Admin/>
                </Route>   
            </Switch>    
        </ScrollToTop>
    </Router>
);

export default Routes;