import Footer from "core/components/Footer";
import Header from "core/components/Header";
import NavBar from "core/components/NavBar";

const Servicos = () => {
  return (
    <>
      <Header />
      <NavBar />
      <div className="container my-4 main-content">        
        <div className="row">
          <h2 className="section-title">Serviços</h2>   
          <ul className="list-marked">
            <li>Instalação de ar condicionado</li>
            <li>Instalação de purificador de água e bebedouro industrial</li>
            <li>Assistência técnica em geral em ar condicionado e purificador de água</li>
            <li>Higienização de ar condicionado residencial e comercial</li>
            <li>Higienização preventiva de ar condicionado</li>
            <li>Higienização de purificador de água</li>
            <li>Troca de refil de purificador de água</li> 
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Servicos;