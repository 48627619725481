import { NavLink} from 'react-router-dom';
import './styles.css';
import 'bootstrap/js/src/collapse.js';
import ProductLinks from '../ProductLinks';

const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-primary main-nav">
      <div className="container">
        <div className="container-fluid">
          <div className="row">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#menu-navbar"
              aria-controls="menu-navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="menu-navbar">
              <ul className="navbar-nav main-menu">
                <li>
                  <NavLink to="/" exact>Início</NavLink>
                </li>
                <li>
                  <NavLink to="/empresa" exact>A empresa</NavLink>
                </li>  
                <ProductLinks />
                <li>
                  <NavLink to="/servicos" exact>Serviços</NavLink>
                </li>
                <li>
                  <NavLink to="/contato" exact>Contato</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;