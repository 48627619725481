import { useEffect, useState, useCallback } from 'react';
import { ProductResponse } from 'core/types/Product';
import { makeRequest } from 'core/utils/request';
import ProductCardLoader from '../components/Loaders/ProductCardLoader';
import Banner from "core/components/Banner";
import Brands from "core/components/Brands";
import Footer from "core/components/Footer";
import Header from "core/components/Header"
import NavBar from "core/components/NavBar";
import ProductCard from '../components/ProductCard';
import { NavLink } from 'react-router-dom';
import './styles.css';

const Home = () => {
  const [productResponse, setProductResponse] = useState<ProductResponse>();
  const [isLoading, setIsLoading] = useState(false);

  const [name] = useState('');

  const getProducts = useCallback(() => {
    const params = {
      linesPerPage: 8,
      name: name,
      orderBy: 'id',
      direction: 'ASC',
      categoryId: 7 //destaques
    }

    setIsLoading(true);
    makeRequest({ url: '/products', params })
      .then(response => setProductResponse(response.data))
      .finally(() => {
        setIsLoading(false);
      })
  }, [name]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (
    <>
      <Header />
      <NavBar />
      <Banner />      
      <div className="container my-4 main-content">   
      {isLoading ? <ProductCardLoader /> : (     
        <div className="row">
          <h2 className="section-title">Produtos</h2>          
          {productResponse?.content.map(product => (              
            <ProductCard key={product.id} product={product} />        
          ))}
          <div className="more-products"><NavLink to="/produtos" exact>ver mais</NavLink></div>          
        </div>
      )}
      </div>
      
      <Brands />
      <Footer />
    </>
  );
}

export default Home;