import { Link, useHistory } from 'react-router-dom';
import './styles.css';
import LogoImg from 'core/assets/images/logo_etc.png';
import { useState } from 'react';

const Header = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const refresh = () => {
    history.replace("/empty");
    setTimeout(() => {
      history.replace("/produtos?buscar=" + search);
    }, 10);
    setSearch("");
  };
  
  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="col-sm-6">            
            <Link to="/" className="logo">
              <img src={LogoImg} alt="Etc e Tal" width="250" />
            </Link>
          </div>
          <div className="col-sm-6">
            <div className="col-md-6">
              <form onSubmit={refresh}>
                  <input className="search-input form-control align-middle" type="search" name="search" placeholder="Buscar aqui..." 
                  value={search} 
                  onChange={(e) => setSearch(e.target.value)} required />
              </form>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;