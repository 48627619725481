import { makePrivateRequest, makeRequest } from 'core/utils/request';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
import BaseForm from '../../BaseForm';
import './styles.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Category, Image } from 'core/types/Product';
import ImageUpload from '../ImageUpload';
import JoditReact from "jodit-react-ts";
import "jodit/build/jodit.min.css";

export type FormState = {
  name: string;
  imgUrl: string;
  categories: Category[];
  images: Image[];
}

type ParamsType = {
  productId: string;
}

const Form = () => {
  const { register, handleSubmit,  errors, setValue, control } = useForm<FormState>();
  const history = useHistory();
  const { productId } = useParams<ParamsType>();
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [images, setImages] = useState<Image[]>([]);
  const [uploadedImgUrl, setUploadedImgUrl] = useState(''); 
  const [productImgUrl, setProductImgUrl] = useState(''); 
  const [description, setDescription] = useState('');
  const [specification, setSpecification] = useState('');
  
  let isEditing = productId !== 'create';
  const formTitle = isEditing ? 'EDITAR UM PRODUTO' : 'CADASTRAR UM PRODUTO';

  const configJodit = {
    height: 400
  };

  function handleDuplicate() {
    isEditing = false;
    history.push('/admin/products/create');
  }

  useEffect(() => {
    if (isEditing) {
      makeRequest({ url: `/products/${productId}` })
        .then(response => {
          setValue('name', response.data.name);          
          setValue('categories', response.data.categories);
          // setValue('images', response.data.productImages);
          // setProductImgUrl(response.data.productImages);
          setImages(response.data.productImages);
          setDescription(response.data.description);
          setSpecification(response.data.specification);
        })
    }

  }, [productId, isEditing, setValue]);

  useEffect(() => {
    setIsLoadingCategories(true);
    makeRequest({ url: '/categories' })
      .then(response => setCategories(response.data.content))
      .finally(() => setIsLoadingCategories(false));
  }, [])

  const onSubmit = (data: FormState) => {
    const payload = {
      ...data,
      description: description,
      specification: specification,
      imgUrl: uploadedImgUrl || productImgUrl
    }

    makePrivateRequest({
      url: isEditing ? `/products/${productId}` : '/products',
      method: isEditing ? 'PUT' : 'POST',
      data:payload
    })
    .then(() => {
      toast.info('Produto salvo com sucesso!')
      if (!isEditing) {
        history.push('/admin/products');
      }
    })
    .catch(() => {
      toast.error('Erro ao salvar produto!')
    })
  }

  const onUploadSuccess = (imgUrl: string) =>{
    setUploadedImgUrl(imgUrl);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BaseForm title={formTitle}>
        { 
          isEditing && (
            <button onClick={handleDuplicate} className="btn btn-primary border-radius-10 btn-action-adm btn-duplicate">
              DUPLICAR
            </button>
        )}
        <div className="product-form-container">          
          <div className="col-10">            
            <div className="margin-bottom-30">
              <input
                ref ={register(
                  {
                    required: "Campo obrigatório",
                    minLength: { value: 5, message: 'O campo deve ter no mínimo 5 caracteres' },
                    maxLength: { value: 60, message: 'O campo deve ter no máximo 60 caracteres' }
                  }
                )}
                name="name"
                type="text"
                className="form-control input-base"
                placeholder="Nome do Produto"
              />
              {errors.name && (
                <div className="invalid-feedback d-block">
                  {errors.name.message}
                </div>
              )}
            </div>

            <div className="margin-bottom-30">
              <Controller
                as={Select}
                defaultValue=""
                name="categories"
                rules={{ required: "Campo obrigatório" }}
                control={control}
                options={categories}
                isLoading={isLoadingCategories}
                getOptionLabel={(option: Category) => option.name}
                getOptionValue={(option: Category) => String(option.id)}
                classNamePrefix="categories-select"
                placeholder="Categorias..."
                isMulti
              />
              {errors.categories && (
                <div className="invalid-feedback d-block">
                  Campo obrigatório!
                </div>
              )}
            </div>            
            <h5>Descrição</h5>
            <div className="margin-bottom-30">              
            <JoditReact onChange={(description) => setDescription(description)} config={configJodit} defaultValue={description} />            
            </div>
            <h5>Especificação</h5>    
            <div className="margin-bottom-30">
              <JoditReact onChange={(specification) => setSpecification(specification)} config={configJodit} defaultValue={specification} />
            </div>
            { isEditing && (
              <div className="margin-bottom-30">
                <ImageUpload
                  onUploadSuccess={onUploadSuccess}
                  productImgUrl={productImgUrl}
                />
                {images.map((item, index) => (              
                  <img width={80}
                    src={item.imgThumbUrl}
                    className="img-fluid"
                    alt={item.id + "title"}
                  />
              ))}            
              </div>
            )
            }         
          </div>          
        </div>
      </BaseForm>
    </form>
  )
}

export default Form;