
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './core/assets/styles/custom.scss';
import './app.scss';
import Routes from 'Routes';
import Offline from 'pages/Site/Offline';

const App = () => {
  return (
    <>
      {(process.env.REACT_APP_OFFLINE === "true" && <Offline />) || <Routes />}      
      <ToastContainer />
    </>
  );
}

export default App;
