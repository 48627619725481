import { getAccessTokenDecoded, logout } from 'core/utils/auth';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './styles.scss';
import menu from 'core/assets/images/menu.svg';

const Navbar = () => {
  const [drawerActive, setDrawerActive] = useState(false);
  const [currentUser, setCurrentUser] = useState('');
  const location = useLocation();

  useEffect(() => {
    const currentUserData = getAccessTokenDecoded();
    setCurrentUser(currentUserData.user_name);
  }, [location])

  const handleLogout = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    logout();
  }

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-primary main-nav-admin">        
        <div className="container-fluid">
          <Link to="/admin" className="nav-logo-text">
            <h4>Admin</h4>
          </Link>
          <button
            className="menu-mobile-btn"
            type="button"
            onClick={() => setDrawerActive(!drawerActive)}
          >
            <img src={menu} alt="Mobile Menu" />
          </button>
          <div className="row">
            <div className={drawerActive ? "menu-mobile-container" : "menu-container"}>
              <ul className="navbar-nav main-menu">
                <li>
                  <NavLink 
                    className="nav-link" 
                    onClick={() => setDrawerActive(false)} 
                    to="/" exact>
                    Site
                  </NavLink>
                </li> 
              {
              drawerActive && ( 
                <li>
                {
                  currentUser && (
                    <a 
                      href="#logout" 
                      className="nav-link active d-inline"
                      onClick={(e) => {
                        setDrawerActive(false)
                        handleLogout(e)
                      }}
                    >
                      {`LOGOUT - ${currentUser}`}
                    </a>
                  )
                }
              </li>
              )
            }
            {
              drawerActive && (
                <>
                  {!currentUser && (
                    <li>
                      <Link 
                        to="/auth/login" 
                        className="nav-link active"
                        onClick={() => setDrawerActive(false)}
                      >
                        LOGIN
                      </Link>
                    </li>
                  )}
  
                </>
              )
            }
              </ul>
            </div>
          </div>
        </div>

        <div className="user-info-dnone text-right">
          {currentUser && (
            <>
              {currentUser}
              <a
                href="#logout"
                className="nav-link active d-inline"
                onClick={(e) => {
                  setDrawerActive(false)
                  handleLogout(e)
                }}
              >
                LOGOUT
              </a>
            </>
          )}
          {!currentUser && (
            <Link 
              to="/auth/login" 
              className="nav-link active" 
              onClick={() => setDrawerActive(false)}
            >
              LOGIN
            </Link>
          )}
        </div>
    </nav>
  )
};

export default Navbar;