import Footer from "core/components/Footer";
import Header from "core/components/Header";
import NavBar from "core/components/NavBar";
import Phone from "core/components/Phone";
import { makeRequest } from "core/utils/request";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import './styles.css';

export type FormContactState = {
  name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}
const Contato = () => {
  const { handleSubmit, errors, register } = useForm<FormContactState>();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const onSubmitContact = (data: FormContactState) => {
        makeRequest({
          url: '/contact',
          method: 'POST',
          data
        })
          .then(() => {
            toast.info('Mensagem enviada com sucesso!');
            setName("");
            setEmail("");
            setPhone("");
            setSubject("");
            setMessage("");
          })
          .catch(() => {
            toast.error('Erro ao enviar!')
          })
    }
  return (
    <>
      <Header />
      <NavBar />
      <div className="container my-4 main-content">        
        <div className="row">
          <h2 className="section-title">Contato</h2>  
          <div className="col-md-6 contact-left">
            <h3>Telefone</h3>            
            <Phone />
            <h3>Localização</h3>
            <ul className="contact-address">              
              <li>
                <i className="fa fa-map-marker"></i> Av. Ildefonso Carneiro, 1156 - Centro - Caçu/GO
              </li>
            </ul>
            <div className="contact-map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.2102604346096!2d-51.13781058455696!3d-18.564557087383484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x949e630011d42e49%3A0x956fdf7f5061a25c!2sEtc%20%26%20Tal%20Eletrodom%C3%A9sticos!5e0!3m2!1spt-BR!2sbr!4v1651422176099!5m2!1spt-BR!2sbr" title="Localização Etc & Tal" width="100%" height="330" style={{border:0}} loading="lazy"></iframe>
            </div>
          </div>
          <div className="col-md-6">
            <form onSubmit={handleSubmit(onSubmitContact)} id="contactForm" name="contactForm">
                <h3>Nos envie um e-mail</h3>
                <div className="row">
                  <div className="col-md-12 form-group">
                      <input ref={register(
                      {
                          required: "Campo obrigatório",
                          minLength: { value: 5, message: 'O campo deve ter no mínimo 5 caracteres' },
                          maxLength: { value: 50, message: 'O campo deve ter no máximo 50 caracteres' }
                      }
                      )}
                      type="text" 
                      className="form-control"
                      id="contact-name" placeholder="Seu nome"
                      name="name" 
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      />
                      {errors.name && (
                          <div className="invalid-feedback d-block">
                          {errors.name.message}
                          </div>
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 form-group">
                      <input ref={register(
                      {
                          required: "Campo obrigatório",
                          minLength: { value: 9, message: 'O campo deve ter no mínimo 9 caracteres' },
                          maxLength: { value: 50, message: 'O campo deve ter no máximo 50 caracteres' }
                      }
                      )}                      
                      type="text" 
                      className="form-control" 
                      name="email" id="contact-email" placeholder="E-mail" 
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                       />
                      {errors.email && (
                          <div className="invalid-feedback d-block">
                          {errors.email.message}
                          </div>
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 form-group">
                      <input ref={register()}
                      type="text" className="form-control" name="phone" id="contact-phone" placeholder="Telefone" 
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 form-group">
                      <input ref={register(
                      {
                          required: "Campo obrigatório",
                          minLength: { value: 3, message: 'O campo deve ter no mínimo 3 caracteres' },
                          maxLength: { value: 50, message: 'O campo deve ter no máximo 50 caracteres' }
                      }
                      )}
                      type="text" className="form-control" name="subject" id="contact-subject" placeholder="Assunto"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      />
                      {errors.subject && (
                          <div className="invalid-feedback d-block">
                          {errors.subject.message}
                          </div>
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 form-group">
                      <textarea ref={register(
                      {
                          required: "Campo obrigatório",
                          minLength: { value: 3, message: 'O campo deve ter no mínimo 3 caracteres' },
                          maxLength: { value: 600, message: 'O campo deve ter no máximo 600 caracteres' }
                      }
                      )}
                      className="form-control" name="message" id="contact-message"placeholder="Escreva sua mensagem"
                      maxLength={600}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                       />
                      {errors.message && (
                          <div className="invalid-feedback d-block">
                          {errors.message.message}
                          </div>
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                      <input type="submit" value="Enviar" className="btn btn-primary rounded-0 py-2 px-4" />
                      <span className="submitting"></span>
                  </div>
                </div>
            </form>
            <div id="form-message-warning mt-4"></div>
            <div id="form-message-success"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contato;