import { useEffect, useState } from 'react';
import { Category, ProductResponse } from 'core/types/Product';
import { makeRequest } from 'core/utils/request';
import Pagination from 'core/components/Pagination';
import { useCallback } from 'react';
import ProductCardLoader from '../components/Loaders/ProductCardLoader';
import ProductCard from '../components/ProductCard';
import Header from 'core/components/Header';
import NavBar from 'core/components/NavBar';
import Footer from 'core/components/Footer';
import { useLocation } from "react-router-dom";

type Props ={
  idCategory: number;
}

const Catalog = ({ idCategory }: Props) => {
  const [productResponse, setProductResponse] = useState<ProductResponse>();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [category, setCategory] = useState<Category>();

  const getProducts = useCallback(() => {
    const params = {
      page: activePage,
      linesPerPage: 12,
      name: query.get('buscar') || '',
      categoryId: idCategory
    }

    setIsLoading(true);
    makeRequest({ url: '/products', params })
      .then(response => setProductResponse(response.data))
      .finally(() => {
        setIsLoading(false);
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [activePage, idCategory]);

  useEffect(() => {
    if (activePage !== 0) {
      setActivePage(0);
    }
    if (idCategory > 0) {
      makeRequest({ url: `/categories/${idCategory}` })
        .then(response => setCategory(response.data));
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [idCategory]);

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  return (
    <>
      <Header />
      <NavBar />      
        <div className="container my-4 main-content">  
          {isLoading ? <ProductCardLoader /> : (
          <>
          <div className="row">            
            <h2 className="section-title">{category?.name || "Produtos"}</h2>
              {
                query.get('buscar') && <p>Buscar por "{query.get('buscar')}":</p>
              }
              {productResponse?.content.map(product => (              
                <ProductCard key={product.id} product={product} />        
              ))}              
          </div>
           {productResponse &&
            <Pagination
              totalPages={productResponse?.totalPages}
              activePage={activePage}
              onChange={page => setActivePage(page)}
            />
           }
          </> 
          )}
        </div>

      <Footer />
    </>
  )
}

export default Catalog;