import { NavLink} from 'react-router-dom';
import 'core/assets/styles/font-awesome.min.css';
import './styles.css';
import Phone from '../Phone';
import { makeRequest } from 'core/utils/request';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import ProductLinks from '../ProductLinks';

export type FormNewsletterState = {
    email: string;
}
const Footer = () => {
    const { handleSubmit, errors, register } = useForm<FormNewsletterState>();
    const [email, setEmail] = useState("");
    const onSubmit = (data: FormNewsletterState) => {
        makeRequest({
          url: '/newsletter',
          method: 'POST',
          data
        })
          .then(() => {
            toast.info('Cadastro efetuado com sucesso!');
            setEmail("");
          })
          .catch(() => {
            toast.error('Erro ao cadastrar!')
          })
    }
    return (
        <>
            <div className="footer-top-area">
                <div className="zigzag-bottom"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="footer-about-us">
                                <h2>Etc & Tal</h2>
                                <Phone />
                                <p>Acompanhe nossas redes sociais:</p>
                                <div className="footer-social">                                    
                                    <a href="https://www.instagram.com/refrigeracaoetcetal" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a>
                                    <a href="https://www.facebook.com/etcetaleletrodomesticoss" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="footer-menu">
                                <h2 className="footer-wid-title">Categorias</h2>
                                <ul>
                                    <ProductLinks />
                                    <li>
                                        <NavLink to="/servicos" exact>Serviços</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                            <div className="footer-newsletter">
                                <h2 className="footer-wid-title">Newsletter</h2>
                                <p>Entre com o seu e-mail:</p>
                                <div className="newsletter-form">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <input ref={register(
                                        {
                                            required: "Campo obrigatório",
                                            minLength: { value: 9, message: 'O campo deve ter no mínimo 9 caracteres' },
                                            maxLength: { value: 50, message: 'O campo deve ter no máximo 50 caracteres' }
                                        }
                                        )}
                                        type="email" 
                                        name="email" 
                                        className="form-control" 
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {errors.email && (
                                            <div className="invalid-feedback d-block">
                                            {errors.email.message}
                                            </div>
                                        )}
                                        <input type="submit" value="Inscrever" />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="copyright">
                                <p>&copy; 2022  - Todos os direitos reservados - Etc & Tal</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Footer;