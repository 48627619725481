import Pagination from "core/components/Pagination";
import { NewsletterResponse } from "core/types/Newsletter";
import { makePrivateRequest } from "core/utils/request";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import CardLoader from "../../Products/Loaders/ProductCardLoader";
import CardNewsletter from "../CardNewsletter";

const ListNewsletter = () => {
  const [newsletterResponse, setNewsletterResponse] = useState<NewsletterResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(0);

  const [email, setEmail] = useState('');
  const [direction, setDirection] = useState('ASC');  

  const onRemove = (categoryId: number) => {
    const confirm = window.confirm('Deseja realmente excluir este cadastro?');

    if (confirm) {
      makePrivateRequest({ url: `/newsletter/${categoryId}`, method: 'DELETE' })
        .then(() => {
          getNewsletter();
          toast.info('Cadastro removido com sucesso!')
        })
        .catch(() => {
          toast.error('Erro ao remover o cadastro!')
        })
    }
  }

  const getNewsletter = useCallback(() => {
    const params = {
      email: email,
      page: activePage,
      linesPerPage: 30,
      direction: direction,
      orderBy: 'email'
    }
    setIsLoading(true);
    makePrivateRequest({ url: '/newsletter', params })
      .then(response => setNewsletterResponse(response.data))
      .finally(() => {
        setIsLoading(false);
      })
  }, [activePage, email, direction])

  useEffect(() => {
    getNewsletter();
  }, [getNewsletter]);

  return (
    <div className="admin-products-list">     
      <div className="admin-list-container">
        {isLoading ? <CardLoader /> : (
          newsletterResponse?.content.map(item => (
            <CardNewsletter newsletter={item} onRemove={onRemove} key={item.id} />
          ))
        )}

        {newsletterResponse &&
          <Pagination
            totalPages={newsletterResponse?.totalPages}
            activePage={activePage}
            onChange={page => setActivePage(page)}
          />
        }
      </div>
    </div>
  );
}

export default ListNewsletter;