import { NavLink} from 'react-router-dom';

const ProductLinks = () => { 
  return (
    <>
    <li>
      <NavLink to="/ar-condicionado" exact>Ar condicionado</NavLink>
    </li>
    <li>
      <NavLink to="/bebedouro" exact>Bebedouro</NavLink>
    </li>
    <li>
      <NavLink to="/climatizador" exact>Climatizador</NavLink>
    </li>    
    <li>
      <NavLink to="/purificador" exact>Purificador</NavLink>
    </li>               
    <li>
      <NavLink to="/acessorio" exact>Acessório</NavLink>
    </li>
    </>
  )
}

export default ProductLinks;