import { Newsletter } from "core/types/Newsletter";

type Props = {
  newsletter: Newsletter;
  onRemove: (newsletterId: number) => void;
}

const CardNewsletter = ({ newsletter, onRemove }: Props) => {
  return (
    <div className="card-base border-radius-10 card-item">
      <div className="main-row">
        <div className="col-name">
          <h2 className="content">{newsletter.email}</h2>
        </div>

        <div className="col-category">
          <div className="row-category-buttons">            
            <div className="category-button">
              <button
                type="button"
                className="btn btn-outline-danger btn-block border-radius-10"
                onClick={() => onRemove(newsletter.id)}
              >
                EXCLUIR
              </button>
            </div>
          </div>

        </div>

      </div>
    </div>

  )
}

export default CardNewsletter;
