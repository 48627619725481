import { Route, Switch } from 'react-router-dom';
import ListNewsletter from './ListNewsletter';

const Newsletter = () => {
  return (
    <div>
      <Switch>
      <Route path="/admin/newsletter" exact>
          <ListNewsletter/>
        </Route>
      </Switch>
    </div>

  );
}

export default Newsletter;