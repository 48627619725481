import './styles.css';

const Phone = () => {
  return (
    <ul className="list-phone">
      <li>
        <a href="tel:6436562043" target="_blank" rel="noreferrer"><i className="fa fa-phone"></i> 64 3656-2043</a>
      </li>
      <li>
        <a href="https://wa.me/5564981291175" target="_blank" rel="noreferrer"><i className="fa fa-whatsapp"></i> 64 98129-1175</a>
      </li>
    </ul>
  );
}

export default Phone;