import ContentLoader from "react-content-loader"
import { generateList } from "core/utils/list"

const ProductCardLoader = () => {
  const loaderItems  = generateList(4);

  return (
    <>
      {loaderItems.map(item => (

        <ContentLoader
          key={item}
          speed={1}
          width={320}
          height={270}
          viewBox="0 0 320 280"
          backgroundColor="#ecebeb"
          foregroundColor="#d6d2d2"
        >

          <rect x="0" y="0" rx="10" ry="10" width="320" height="270" />
        </ContentLoader>

      ))}
    </>
  )
}

export default ProductCardLoader;